import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import ContextStore from './components/Context/ContextStore';


function App() {
  
  const [contextStore, setContextStore] = useState({
    showModal: false
  })
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Clayburn FinTech LLC");

  return (
    <>
      <ContextStore.Provider value={{contextStore, setContextStore}}>
      <Router>
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-demo" element={<DemoProduct />} /> 
        </Routes>
      
    </Router>
      </ContextStore.Provider>
    </>
  );
}


export default App;
