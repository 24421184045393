import React, { useContext, useState } from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import { useDocTitle } from '../components/CustomHook';
import ResponseModal from '../modal/ResponseModal';
import ContextStore from '../components/Context/ContextStore';

const Home = () => {
  useDocTitle('Clayburn FinTech LLC');
  const [open, setOpen] = useState(true);
  const {contextStore, setContextStore} = useContext(ContextStore)
  return (
    <>
      <Hero />
      <Intro />
      <Services />
      <Portfolio />
      {/* <Clients /> */}
      <Cta />
      <Footer />
      {contextStore.showModal && <ResponseModal setOpen={() => setContextStore({...contextStore, showModal: false})} />}
    </>
  );
};

export default Home;
