import React, { useContext, useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
// import emailjs from 'emailjs-com';
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import ContextStore from "../components/Context/ContextStore";

const Contact = () => {
    useDocTitle("CFL | Send us a message");
    const { contextStore, setContextStore } = useContext(ContextStore);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [sendingData, setSendingData] = useState(false);
    const clearErrors = () => {
        setErrors([]);
    };

    const clearInput = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
    };

    // Function to validate the form fields
    const validateForm = () => {
        const newErrors = {};

        if (!firstName) {
            newErrors.first_name = "First Name is required";
        }

        if (!lastName) {
            newErrors.last_name = "Last Name is required";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Invalid email format";
        }

        if (!phone) {
            newErrors.phone_number = "Phone number is required";
        }

        if (!message) {
            newErrors.message = "Message is required";
        }

        setErrors(newErrors);

        // Check if the form is valid
        return Object.keys(newErrors).length === 0;
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        // Check form validation
        if (!validateForm()) {
            return;
        }
        // Disable submit button and show loading text
        // document.getElementById('submitBtn').disabled = true;
        // document.getElementById('submitBtn').innerHTML = 'Loading...';
        setSendingData(true);
        // Create FormData with form data
        const response = await axios.post(
            "https://ra.clayburnfintech.com/contact",
            { firstName, lastName, email, phone, message }
        );
        console.log(response.data);
        setSendingData(false);
        setContextStore({ ...contextStore, showModal: true });
        navigate("/");
    };
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
                <div
                    className="container mx-auto my-8 px-4 lg:px-20"
                    data-aos="zoom-in"
                >
                    {/* <form onSubmit={sendEmail}>
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                                    Send us a message
                                </h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && (
                                        <p className="text-red-500 text-sm">
                                            {errors.first_name}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && (
                                        <p className="text-red-500 text-sm">
                                            {errors.last_name}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && (
                                        <p className="text-red-500 text-sm">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && (
                                        <p className="text-red-500 text-sm">
                                            {errors.phone_number}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    placeholder="Message*"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors && (
                                    <p className="text-red-500 text-sm">
                                        {errors.message}
                                    </p>
                                )}
                            </div>
                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button
                                    type="submit"
                                    id="submitBtn"
                                    disabled={sendingData}
                                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                                >
                                    {sendingData
                                        ? "Loading..."
                                        : "Send Message"}
                                </button>
                            </div>
                        </div>
                    </form> */}
                    <div className="w-full  lg:w-1/6   bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">
                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <div className="mt-5">
                                        <h2 className="text-2xl">
                                            Send an E-mail
                                        </h2>
                                        <p className="text-gray-400">
                                            info@clayburnfintech.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
